import { DEFAULT_ISSUES_COLUMNS } from "@/constants"

export default {
  name: "IssueColumnsPreference",
  data: () => ({
    issuesColumnsSearch               : undefined,
    localIssuesColumnsPreference      : undefined,
    isIssuesColumnsPreferenceAvailable: false,
    isIssueFieldsAvailable            : false,
    isFieldsAvailable                 : false,
    draggedIssuesColumn               : undefined,
    dragEnteredIssuesColumn           : undefined,
    isRestoringPreference             : false,
    isSavingPreferences               : false
  }),
  props: {
    pIssuesColumnsPreference: Array,
    pIssueFields            : Array,
    pFieldsMap              : Object,
    pIsSaving               : Boolean
  },
  methods: {
    isIssueColumnVisible(issuesColumnPreference) {
      return !this.issuesColumnsSearch ||
        issuesColumnPreference.name.toUpperCase().includes(this.issuesColumnsSearch.toUpperCase())
    },
    classForIssuesColumn() {
      return "white"
    },
    onIssuesColumnDragStart(issuesColumn) {
      this.draggedIssuesColumn     = issuesColumn
      this.dragEnteredIssuesColumn = this.draggedIssuesColumn
    },
    onIssuesColumnDragEnter(issuesColumn) {
      this.dragEnteredIssuesColumn = issuesColumn
    },
    onIssuesColumnDragOver(event) {
      event.preventDefault()
    },
    onIssuesColumnDragEnd() {
      if (this.draggedIssuesColumn.column !== this.dragEnteredIssuesColumn.column) {
        const indexOfDraggedIssuesColumn     = this.localIssuesColumnsPreference.findIndex(issuesColumn =>
          issuesColumn.column === this.draggedIssuesColumn.column
        )
        const indexOfDragEnteredIssuesColumn = this.localIssuesColumnsPreference.findIndex(issuesColumn =>
          issuesColumn.column === this.dragEnteredIssuesColumn.column
        )

        this.localIssuesColumnsPreference.splice(indexOfDraggedIssuesColumn, 1)
        this.localIssuesColumnsPreference.splice(indexOfDragEnteredIssuesColumn, 0, this.draggedIssuesColumn)
      }
    },
    handleSave() {
      this.isSavingPreferences = true
      this.$emit("save", this.localIssuesColumnsPreference.map(issuesColumnPreference => ({
        column  : issuesColumnPreference.column,
        selected: issuesColumnPreference.selected
      })))
    },
    computeLocalIssuesColumnsPreference() {
      this.localIssuesColumnsPreference = []

      const issuesHeadersMap = new Map()
      for (const issuesHeader of this.$TABLES.ISSUES.headers) {
        if (!(["id", "icon"].includes(issuesHeader.value))) {
          issuesHeadersMap.set(issuesHeader.value, issuesHeader)
        }
      }

      const issueFieldsMap = new Map()
      for (const issueField of this.pIssueFields) {
        issueFieldsMap.set(issueField.id, issueField)
      }

      for (const issuesColumnPreference of this.pIssuesColumnsPreference) {
        const issuesHeader = issuesHeadersMap.get(issuesColumnPreference.column)
        const issueField   = issueFieldsMap.get(issuesColumnPreference.column)

        if (issuesHeader) {
          this.localIssuesColumnsPreference.push({
            column  : issuesHeader.value,
            selected: issuesColumnPreference.selected,
            name    : this.$t(issuesHeader.text)
          })
          issuesHeadersMap.delete(issuesColumnPreference.column)
        } else if (issueField) {
          this.localIssuesColumnsPreference.push({
            column  : issueField.id,
            selected: issuesColumnPreference.selected,
            name    : this.pFieldsMap[issueField.fieldId].systemName
          })
          issueFieldsMap.delete(issuesColumnPreference.column)
        }
      }

      for (const [headerValue, header] of issuesHeadersMap) {
        this.localIssuesColumnsPreference.push({
          column  : headerValue,
          selected: false,
          name    : this.$t(header.text)
        })
      }
      for (const [issueFieldId, issueField] of issueFieldsMap) {
        this.localIssuesColumnsPreference.push({
          column  : issueFieldId,
          selected: false,
          name    : this.pFieldsMap[issueField.fieldId].systemName
        })
      }
    },
    handleRestoreToDefault() {
      this.isRestoringPreference        = true
      this.localIssuesColumnsPreference = []
      for (const header of this.$TABLES.ISSUES.headers) {
        if (!(["id", "icon"].includes(header.value))) {
          this.localIssuesColumnsPreference.push({
            column  : header.value,
            selected: !!DEFAULT_ISSUES_COLUMNS.includes(header.value),
            name    : this.$t(header.text)
          })
        }
      }
      for (const issueField of this.pIssueFields) {
        this.localIssuesColumnsPreference.push({
          column  : issueField.id,
          selected: false,
          name    : this.pFieldsMap[issueField.fieldId].systemName
        })
      }
      this.$emit("save", this.localIssuesColumnsPreference.map(issuesColumnPreference => ({
        column  : issuesColumnPreference.column,
        selected: issuesColumnPreference.selected
      })))
    }
  },
  computed: {
    areIssuesColumnsDraggable() {
      return !this.issuesColumnsSearch || !this.issuesColumnsSearch.length
    },
    canComputeLocalIssuesColumnsPreference() {
      return this.isFieldsAvailable && this.isIssueFieldsAvailable && this.isIssuesColumnsPreferenceAvailable
    }
  },
  watch: {
    pIssueFields: {
      immediate: true,
      handler  : function(newValue) {
        this.isIssueFieldsAvailable = !!newValue
      }
    },
    pFieldsMap: {
      immediate: true,
      handler  : function(newValue) {
        this.isFieldsAvailable = !!newValue
      }
    },
    pIssuesColumnsPreference: {
      immediate: true,
      handler  : function(newValue) {
        this.isIssuesColumnsPreferenceAvailable = !!newValue
      }
    },
    canComputeLocalIssuesColumnsPreference: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          this.computeLocalIssuesColumnsPreference()
        }
      }
    },
    pIsSaving: {
      handler: function(newValue) {
        if (!newValue) {
          this.isRestoringPreference = false
          this.isSavingPreferences   = false
        }
      }
    }
  }
}