/**
 * @file It contains scripts for issues page.
 */
import { mapGetters, mapActions } from "vuex"
import ThemisIssueFilters from "@/components/issue-filters"
import { DATA_EXPORT_STATUS, FIELD_TYPES, ISSUE_TYPES, ISSUE_SEARCH_STATUS, ISSUE_SEARCH_POLL_INTERVAL } from "@/constants"
import ThemisIssuesColumnsPreference from "@/components/issues-columns-preference"

export default {
  name      : "Issues",
  components: { ThemisIssueFilters, ThemisIssuesColumnsPreference },
  data() {
    return {
      localIssuesFiltersPreference: {},
      advancedFilters             : [
        {
          header: this.$t("1246")
        },
        {
          name : this.$t("227"),
          value: this.$t("227")
        },
        {
          name : this.$t("292"),
          value: this.$t("292")
        },
        {
          name : this.$t("291"),
          value: this.$t("291")
        },
        {
          name : this.$t("1003"),
          value: this.$t("1003")
        },
        {
          name : this.$t("694"),
          value: this.$t("694")
        },
        {
          header: this.$t("1247")
        },
        {
          name : this.$t("285"),
          value: this.$t("285")
        },
        {
          name : this.$t("1161"),
          value: this.$t("1161")
        },
        {
          name : this.$t("631"),
          value: this.$t("631")
        },
        {
          name : this.$t("1160"),
          value: this.$t("1160")
        }
      ],
      issueTypes: [{
        header: this.$t("1272")
      },
      {
        name : ISSUE_TYPES[0].name,
        value: ISSUE_TYPES[0].id
      },
      {
        header: this.$t("1273")
      },
      {
        name : ISSUE_TYPES[1].name,
        value: ISSUE_TYPES[1].id
      }],
      allFilters                : new Array(),
      filterCriteria            : {},
      kpiFilterCriteria         : new Array(),
      issueAnonymisationStatuses: [
        { name: this.$t("632"), value: this.$CONSTANTS.DATA_RETENTION_STATUS.NOT_SCHEDULED },
        { name: this.$t("633"), value: this.$CONSTANTS.DATA_RETENTION_STATUS.SCHEDULED },
        { name: this.$t("634"), value: this.$CONSTANTS.DATA_RETENTION_STATUS.ANONYMISED }
      ],
      reportStatuses: [
        { name: this.$t("636"), value: this.$CONSTANTS.REPORT_STATUS.CLOSED },
        { name: this.$t("635"), value: this.$CONSTANTS.REPORT_STATUS.NEW }
      ],
      reportSources: [
        { name: this.$t("1162"), value: this.$CONSTANTS.REPORT_SOURCE.WEB },
        { name: this.$t("1163"), value: this.$CONSTANTS.REPORT_SOURCE.APP },
        { name: this.$t("1164"), value: this.$CONSTANTS.REPORT_SOURCE.PHONE }
      ],
      showIssuesColumnsPreference: false,
      searchText                 : null,
      searchThroughAllIssueData  : false,
      issueSearchPollingInterval : null
    }
  },
  beforeDestroy() {
    this.clearIssueSearchPollingInterval()
  },
  methods: {
    ...mapActions({
      addExport                  : "exports/addExport",
      updateUser                 : "users/updateUser",
      loadIssueSearch            : "issueSearch/loadIssueSearch",
      loadIssueSearchWithCriteria: "issueSearch/loadIssueSearchWithCriteria",
      loadIssuesFromLastSearch   : "issues/loadIssuesFromLastSearch"
    }),
    filterIssues() {
      const query = {}
      for (const filter of this.kpiFilterCriteria) {
        if (filter === 0) {
          query.unread = true
        } else if(filter === 1) {
          query.unanswered = true
        }
      }
      this.$router.replace({
        name: this.$route.name,
        query
      })
    },
    updateUserPreferenceForRowsPerPage(itemsPerPage) {
      this.updateUser({ id: this.loggedInUser.id, issuesRowsPerPagePreference: itemsPerPage })
    },
    /**
     * utility to generate search criteria which can be directly queried on issues table or store
     * @param {*} filters - contains all the filters made by user on issues
     */
    generateSearchCriteria(filters) {
      this.filterCriteria = {}
      for (const filter of filters) {
        const entityName    = filter.key[0]
        const entityId      = filter.key[1]
        const updatedFilter = {}
        if (entityId === this.$CONSTANTS.ISSUE_SEARCH_FILTERS.FORM_TEMPLATE) {
          const formFieldIndex      = Object.keys(this.filterCriteria).filter(criteria =>
            criteria.indexOf(this.$CONSTANTS.ISSUE_SEARCH_FILTERS.FORM_TEMPLATE) !== -1).length / 2
          const fieldId             = this.$CONSTANTS.ISSUE_SEARCH_FILTERS.FORM_TEMPLATE.concat("[").concat(formFieldIndex).concat("].fieldId")
          const fieldValue          = this.$CONSTANTS.ISSUE_SEARCH_FILTERS.FORM_TEMPLATE.concat("[").concat(formFieldIndex).concat("].formInstanceFieldValues[0].value")
          updatedFilter[fieldId]    = filter.key[2].fieldId
          updatedFilter[fieldValue] = [...filter.values]
        } else if (entityId === this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ISSUE_FIELD) {
          const issueFieldIndex = Object.keys(this.filterCriteria).filter(criteria =>
            criteria.indexOf(this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ISSUE_FIELD) !== -1).length / 2

          const issueFieldId    = this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ISSUE_FIELD.concat("[").concat(issueFieldIndex).concat("].issueFieldId")
          const issueFieldValue = this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ISSUE_FIELD.concat("[").concat(issueFieldIndex).concat("].value")

          updatedFilter[issueFieldId]    = filter.key[2].issueFieldId
          updatedFilter[issueFieldValue] = [...filter.values]
        } else {
          switch (entityName) {
            case this.$CONSTANTS.ISSUE_SEARCH_FILTERS.DATE_RANGE[0]:
              updatedFilter[entityId] = [filter.values[0].concat("T00:00:00"), filter.values[1].concat("T23:59:59")].join("to")
              break

            default:
              updatedFilter[entityId] = [...filter.values]
              break
          }
        }
        this.filterCriteria = Object.assign({}, this.filterCriteria, updatedFilter)
      }
    },
    /**
     * This method converts the flattened json with dot notation into its expanded representation
     * Eg. {"report[0].channel": 2, "issueStatuses": [23, 34]}
     *      will be resolved to
     *  {
     *    "report": [{
     *      "channel": 2
     *    }],
     *    "issueStatuses": [23, 34]
     *  }
     * unflattenJson will point to the root of object(at starting it will be {}), tempObject will point inside array or array of object(it goes into deeper layer like inside array of object and inside that object)
     * We split the key of filterCriteria("report[0].channel") and checks whether it is an array("report[0]") or an object key("channel")
     * If it's an array we create a new array with empty object with index('0' for "report[0]") only if there is no same array name present in the same layer
     * If its an object key, we create an object key and assign the value to it("2") only if its last index of split result
     * At the last index of split result, in the end we make the tempObject point to the root of unflattenJson
     */
    generateUnflattenJson() {
      const arrayElementRegex = /^[a-zA-Z]+\[[0-9]+\]$/
      const unflattenJson     = {}
      let tempObject          = unflattenJson
      for (const fullyQualifiedEntityName of Object.keys(this.filterCriteria)) {
        fullyQualifiedEntityName.split(".").map((key, index, entityNames) => {
          let arrayIndex = -1
          if (index === entityNames.length - 1) {
            tempObject[key] = this.filterCriteria[fullyQualifiedEntityName]
          } else if (arrayElementRegex.test(key)) {
            const startArrayIndex = key.indexOf("[")
            const endArrayIndex   = key.indexOf("]")
            arrayIndex            = parseInt(key.substring(startArrayIndex + 1, endArrayIndex))
            key                   = key.substring(0, startArrayIndex)
            if (!tempObject[key]) {
              tempObject[key]             = []
              tempObject[key][arrayIndex] = {}
            } else if (!tempObject[key][arrayIndex]){
              tempObject[key][arrayIndex] = {}
            }
          } else {
            tempObject[key] = {}
          }
          if (arrayIndex !== -1) {
            tempObject = tempObject[key][arrayIndex]
          } else {
            tempObject = unflattenJson
          }
        })
      }
      return unflattenJson
    },
    /**
     * Whenever we apply filter for a field in issue, that field along with chosen value is maintained in an array and API call is made.
     * For changes on properties apart from first element of array, the filter is applied based on previous results fetched from API.
     * Whenever all the filtered values of first element of array is removed, then the corresponding entry is removed from array
     * And all the elements in array is shifted by one position. Subsequent API calls will be made, only on changes of new first element
     * @param {*} entity - array having filter name and corresponding field name in issue table. For form fields, this array will also include form field id as third element in array
     * @param {*} values - values filtered for selected field
     */
    applyFilter(entity, values) {
      if (entity && values) {
        if (!this.allFilters.length && values.length) {
          if(!this.allFilters.find(filter => filter.key[0] === entity[0])) {
            this.allFilters.push({ key: entity, values: values })
          }
        } else {
          const filterIndex = this.allFilters.findIndex(filter =>
            filter.key[0] === entity[0] &&
            filter.key[1] === entity[1]
          )
          if (filterIndex === -1 && values.length) {
            this.allFilters.push({ key: entity, values: values })
          } else {
            if (values.length) {
              this.allFilters.splice(filterIndex, 1, { key: entity, values: values })
            } else {
              this.allFilters = this.allFilters.filter(filter =>
                filter.key[0] !== entity[0] ||
                filter.key[1] !== entity[1]
              )
            }
          }
        }
      } else {
        this.allFilters = []
      }

      this.generateSearchCriteria(this.allFilters)
      this.localIssuesFiltersPreference = this.generateUnflattenJson()
    },
    handleSaveIssuesColumnsPreference(issuesColumnsPreference) {
      this.updateUser({
        id                     : this.loggedInUser.id,
        issuesColumnsPreference: issuesColumnsPreference
      })
    },
    getDefaultSortColumn() {
      return this.headersForTable.find(header =>
        header.value === "lastUpdated"
      )?.value
    },
    exportIssues(type) {
      this.addExport({
        type
      })
    },
    clearIssueSearchPollingInterval() {
      if (this.issueSearchPollingInterval) {
        clearInterval(this.issueSearchPollingInterval)
        this.issueSearchPollingInterval = null
      }
    },
    handleIssueSearch() {
      if (
        !this.isLoadingIssues
        && this.isSearchCriteriaChanged
        && this.isIssueSearchCompleted
      ) {
        this.loadIssueSearchWithCriteria({
          criteria: {
            searchText               : this.searchText,
            issuesFilters            : this.localIssuesFiltersPreference,
            searchThroughAllIssueData: this.searchThroughAllIssueData
          }
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      issues                                 : "issues/issues",
      isLoadingIssues                        : "issues/isLoadingIssues",
      domains                                : "domains/domains",
      issueStatuses                          : "issueStatuses/issueStatuses",
      issueResolutions                       : "issueResolutions/issueResolutions",
      users                                  : "users/users",
      usersIncludingDeletedUsers             : "users/usersIncludingDeletedUsers",
      channels                               : "channels/channels",
      labels                                 : "labels/labels",
      loggedInUserPolicies                   : "accessControl/loggedInUserPolicies",
      issuesWithUnreadMessages               : "kpis/issuesWithUnreadMessages",
      issuesWithUnansweredReport             : "kpis/issuesWithUnansweredReport",
      optionListItems                        : "optionListItems/optionListItems",
      fields                                 : "fields/fieldsV2",
      formTemplateConfigurations             : "formTemplateConfigurations/formTemplateConfigurations",
      loggedInUser                           : "auth/loggedInUser",
      issueFields                            : "issueFields/issueFields",
      loggedInUserIssuesColumnsPreference    : "users/loggedInUserIssuesColumnsPreference",
      isUpdatingIssuesColumnsPreference      : "users/isUpdatingIssuesColumnsPreference",
      isIssuesColumnsPreferenceUpdated       : "users/isIssuesColumnsPreferenceUpdated",
      loggedInUserIssuesRowsPerPagePreference: "users/loggedInUserIssuesRowsPerPagePreference",
      issueFieldValues                       : "issueFieldValues/issueFieldValues",
      isLoadingIssueFieldValues              : "issueFieldValues/isLoadingIssueFieldValues",
      languages                              : "languages/languages",
      isAddingExport                         : "exports/isAddingExport",
      latestIssuesExport                     : "exports/latestIssuesExport",
      loggedInUserIssuesFiltersPreference    : "users/loggedInUserIssuesFiltersPreference",
      isLoadingIssueSearch                   : "issueSearch/isLoadingIssueSearch",
      issueSearch                            : "issueSearch/issueSearch"
    }),
    latestIssuesExportStatus() {
      return this.latestIssuesExport?.status
    },
    isIssueFieldsExportInProgress() {
      return this.isAddingExport || this.isIssuesExportInitiated
    },
    isIssuesExportInitiated() {
      return this.latestIssuesExportStatus === DATA_EXPORT_STATUS.INITIATED
    },
    issueResolutionsMap() {
      const issueResolutionsMap = new Object()
      for (const issueResolution of this.issueResolutions) {
        issueResolutionsMap[issueResolution.id] = issueResolution
      }
      return issueResolutionsMap
    },
    issueStatusesMap() {
      const issueStatusesMap = new Object()
      for (const issueStatus of this.issueStatuses) {
        issueStatusesMap[issueStatus.id] = issueStatus
      }
      return issueStatusesMap
    },
    issuesForTable: function() {
      return this.filteredIssues.map(issue => {
        const user             = this.usersIncludingDeletedUsers.find(
          user => user.id === issue.assigneeId
        )
        const issueFieldValues = this.issueFieldValues.filter(issueFieldValue =>
          issueFieldValue.issueId === issue.id
        ).reduce((accumulator, current) => {
          const issueField = this.issueFields.find(issueField => issueField.id === current.issueFieldId)
          if (issueField) {
            const field = this.fieldsMap[issueField.fieldId]
            if (field) {
              switch (field.type) {
                case FIELD_TYPES.DATE.value: {
                  accumulator[current.issueFieldId.toString()] = current.value ?
                    this.$moment(current.value).format("DD MMMM YYYY") : undefined
                  break
                }
                case FIELD_TYPES.DATE_TIME.value: {
                  accumulator[current.issueFieldId.toString()] = current.value ?
                    this.$moment(current.value).format("DD MMMM YYYY HH:mm:ss (UTCZ)") : undefined
                  break
                }
                case FIELD_TYPES.BOOLEAN.value: {
                  accumulator[current.issueFieldId.toString()] = current.value === "true" ? this.$t("1048") : this.$t("1049")
                  break
                }
                case FIELD_TYPES.MULTIPLE_OPTION_LIST.value: {
                  if (current.value) {
                    if (!accumulator[current.issueFieldId.toString()]) {
                      accumulator[current.issueFieldId.toString()] = []
                    }
                    accumulator[current.issueFieldId.toString()].push(current.value)
                  }
                  break
                }
                default: {
                  accumulator[current.issueFieldId.toString()] = current.value
                  break
                }
              }
            }
          }
          return accumulator

        }, {})

        return {
          id               : issue.id,
          lastUpdated      : this.$moment(issue.updatedAt).format("DD MMMM YYYY HH:mm:ss (UTCZ)"),
          domain           : this.domains.find(domain => domain.id === issue.domainId)?.name,
          status           : this.issueStatusesMap[issue.statusId]?.name,
          assignee         : user?.name,
          enabled          : user?.enabled,
          labels           : issue.labels,
          closedOn         : issue.closedAt ? this.$moment(issue.closedAt).format("DD MMMM YYYY HH:mm:ss (UTCZ)") : undefined,
          acknowledgedOn   : issue.acknowledgedAt ? this.$moment(issue.acknowledgedAt).format("DD MMMM YYYY HH:mm:ss (UTCZ)") : undefined,
          receivedOn       : issue.receivedAt ? this.$moment(issue.receivedAt).format("DD MMMM YYYY HH:mm:ss (UTCZ)") : undefined,
          resolution       : this.issueResolutionsMap[issue.resolutionId]?.name,
          summary          : issue.summary,
          createdOn        : this.$moment(issue.createdAt).format("DD MMMM YYYY HH:mm:ss (UTCZ)"),
          deletedAt        : user?.deletedAt,
          type             : issue.typeId,
          parentId         : issue.parentId,
          dataRetainedUntil: issue.dataRetainedUntil ? this.$moment(issue.dataRetainedUntil).format("DD MMMM YYYY") : undefined,
          ...issueFieldValues
        }
      })
    },
    headersForTable() {
      const iconHeader      = this.$TABLES.ISSUES.headers[0]
      const idHeader        = this.$TABLES.ISSUES.headers[1]
      const headersForTable = [{
        ...iconHeader
      }, {
        ...idHeader,
        text: this.$t(idHeader.text)
      }]
      for (const issuesColumnPreference of this.loggedInUserIssuesColumnsPreference) {
        if (issuesColumnPreference.selected) {
          if (isNaN(issuesColumnPreference.column)) {
            const header = this.$TABLES.ISSUES.headers.find(item => item.value === issuesColumnPreference.column)
            headersForTable.push({
              ...header,
              text: this.$t(header.text)
            })
          } else {
            const issueField = this.issueFields.find(issueField => issueField.id === issuesColumnPreference.column)
            if (issueField) {
              const field = this.fieldsMap[issueField.fieldId]
              if (field) {
                switch (field.type) {
                  case FIELD_TYPES.DATE.value: {
                    headersForTable.push(this.$TABLES.ISSUES.dateColumn(field.systemName, issueField.id))
                    break
                  }
                  case FIELD_TYPES.DATE_TIME.value: {
                    headersForTable.push(this.$TABLES.ISSUES.dateTimeColumn(field.systemName, issueField.id))
                    break
                  }
                  case FIELD_TYPES.BOOLEAN.value: {
                    headersForTable.push(this.$TABLES.ISSUES.booleanColumn(field.systemName, issueField.id))
                    break
                  }
                  case FIELD_TYPES.MULTIPLE_OPTION_LIST.value: {
                    headersForTable.push(this.$TABLES.ISSUES.textColumn(field.systemName, issueField.id, false))
                    break
                  }
                  default: {
                    headersForTable.push(this.$TABLES.ISSUES.textColumn(field.systemName, issueField.id))
                    break
                  }
                }
              }
            }
          }
        }
      }
      return headersForTable
    },
    footersForTable() {
      return {
        ...this.$TABLES.ISSUES.footer, ...{
          itemsPerPageText: this.$t(this.$TABLES.ISSUES.footer.itemsPerPageText)
        }
      }
    },
    fieldsMap() {
      const fieldsMap = {}
      for  (const field of this.fields) {
        fieldsMap[field.id] = field
      }
      return fieldsMap
    },
    /**
     * We are filtering the filtered issues when there is a kpiFilterCriteria
     */
    filteredIssues() {
      let filteredIssues = this.issues

      if (this.kpiFilterCriteria.length) {
        filteredIssues = filteredIssues.filter(issue => {
          if (this.kpiFilterCriteria.includes(0) && !this.issuesWithUnreadMessages.includes(issue.id)) {
            return false
          }
          if (this.kpiFilterCriteria.includes(1) && !this.issuesWithUnansweredReport.includes(issue.id)) {
            return false
          }
          return true
        })
      }
      return filteredIssues
    },
    hasAccessToIssueAdd() {
      return !!this.loggedInUserPolicies["Issue add"]
    },
    isUserIssuesFilterPreferenceChanged() {
      return JSON.stringify(this.loggedInUserIssuesFiltersPreference) !==
      JSON.stringify(this.localIssuesFiltersPreference)
    },
    latestIssueSearchStatus() {
      return this.issueSearch?.status
    },
    isIssueSearchInitiated() {
      return this.issueSearch?.status === ISSUE_SEARCH_STATUS.INITIATED
    },
    isIssueSearchCompleted() {
      return this.issueSearch?.status === ISSUE_SEARCH_STATUS.COMPLETED
    },
    isSearchTextChanged() {
      return this.issueSearch?.criteria && this.searchText !== this.issueSearch.criteria.searchText
    },
    isSearchThroughAllIssueDataChanged() {
      return this.issueSearch?.criteria
      && this.searchThroughAllIssueData !== this.issueSearch?.criteria?.searchThroughAllIssueData
    },
    isSearchCriteriaChanged() {
      return this.isUserIssuesFilterPreferenceChanged
      || this.isSearchTextChanged
      || this.isSearchThroughAllIssueDataChanged
    }
  },
  watch: {
    formTemplateConfigurations: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          const filteredFields       = newValue
            .filter(formTemplateConfiguration =>
              this.fieldsMap[formTemplateConfiguration.fieldId].type === FIELD_TYPES.OPTION_LIST.value)
            .map(formTemplateConfiguration =>
              this.fieldsMap[formTemplateConfiguration.fieldId])
          const uniqueFilteredFields = [...new Set(filteredFields)]
          uniqueFilteredFields?.forEach(field => this.advancedFilters.push({
            name : field.systemName,
            value: `reporter-intake-forms-${field.systemName}`
          }))

        }
      }
    },
    issueFields: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          const indexOfReportHeader       = this.advancedFilters.findIndex(filter => filter.header === this.$t("1247"))
          const filteredIssueFields       = newValue
            .filter(issueField => this.fieldsMap[issueField.fieldId].type === FIELD_TYPES.OPTION_LIST.value
            )
            .map(issueField =>
              this.fieldsMap[issueField.fieldId]
            )
          const uniqueFilteredIssueFields = [...new Set(filteredIssueFields)]

          uniqueFilteredIssueFields?.forEach(field =>

            this.advancedFilters.splice(indexOfReportHeader, 0, {
              name : field.systemName,
              value: `issue-fields-${field.systemName}`
            })
          )
        }
      }
    },
    "$route.query.unanswered": {
      immediate: true,
      handler  : function(newValue) {
        if(newValue && !this.kpiFilterCriteria.includes(1)) {
          this.kpiFilterCriteria.push(1)
        }
      }
    },
    "$route.query.unread": {
      immediate: true,
      handler  : function(newValue) {
        if(newValue && !this.kpiFilterCriteria.includes(0)) {
          this.kpiFilterCriteria.push(0)
        }
      }
    },
    isUserIssuesFilterPreferenceChanged: {
      deep   : true,
      handler: function(newValue) {
        if (newValue) {
          if (!this.isLoadingIssues && this.isIssueSearchCompleted) {
            this.loadIssueSearchWithCriteria({
              criteria: {
                searchText               : this.searchText,
                issuesFilters            : this.localIssuesFiltersPreference,
                searchThroughAllIssueData: this.searchThroughAllIssueData
              }
            })
          }
        }
      }
    },
    isIssuesColumnsPreferenceUpdated: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          this.showIssuesColumnsPreference = false
        }
      }
    },
    latestIssueSearchStatus: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue === ISSUE_SEARCH_STATUS.INITIATED && !this.isLoadingIssueSearch) {
          this.issueSearchPollingInterval = setInterval(() => {
            this.loadIssueSearch({ id: this.issueSearch.id })
          }, ISSUE_SEARCH_POLL_INTERVAL)
        }

        if (newValue === ISSUE_SEARCH_STATUS.COMPLETED) {
          this.clearIssueSearchPollingInterval()
          this.loadIssuesFromLastSearch()
        }
      }
    },
    isLoadingIssues: {
      immediate: true,
      handler  : function(newValue) {
        if (
          !newValue
          && this.isIssueSearchCompleted
          && this.isSearchCriteriaChanged
          && !this.isLoadingIssueSearch
        ) {
          this.loadIssueSearchWithCriteria({
            criteria: {
              searchText               : this.searchText,
              issuesFilters            : this.localIssuesFiltersPreference,
              searchThroughAllIssueData: this.searchThroughAllIssueData
            }
          })
        }
      }
    }
  }
}